import "./feedback.css";

function BasicExample() {
  return (
    <div className="feedback">
      <p>
        You can contact us.
      </p>
      <p>by email: info@proslylimited.com</p>
    </div>
  );
}

export default BasicExample;
